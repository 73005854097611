import React from 'react';
import PageHeading from './common/PageHeading';
import FileListItem from './FileListItem';

interface ReferenceMaterialsProps {
  files: any[];
}

const ReferenceMaterials = ({ files }: ReferenceMaterialsProps) => {
  return (
    <div className="pt-16 pb-16">
      <PageHeading>Reference Materials</PageHeading>
      <ul>
        {files.map(({ id, name, file }) => {
          return (
            <li key={`references-${id}`} className="pb-4">
              {!!file?.url && <FileListItem name={name} url={file?.url} />}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ReferenceMaterials;
