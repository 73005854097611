import { signIn } from 'next-auth/client';
import Link from 'next/link';
import { useReducer } from 'react';
import { Credentials } from '../types/Auth';
import Button from './common/Button';
import ErrorMessage from './ErrorMessage';
import FormInput from './FormInput';
import FormLabel from './FormLabel';

interface State {
  error?: string;
  loading?: boolean;
}

const SignInForm = () => {
  const initialState: State | Credentials = {
    error: '',
    loading: false,
    identifier: '',
    password: '',
  };
  const reducer = (state, action) => {
    switch (action.type) {
      default: {
        const result = { ...state };
        result[action.type] = action.value;
        return result;
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    dispatch({ type: name, value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: 'loading', value: true });
    const res = await signIn('credentials', {
      identifier: state.identifier,
      password: state.password,
      redirect: false,
    });

    if (res?.error) {
      dispatch({ type: 'error', value: res.error });
      dispatch({ type: 'loading', value: false });
    }
    dispatch({ type: 'loading', value: false });
    return res;
  };

  return (
    <>
      <div className="flex flex-col mb-8">
        <p className="mb-8">
          Welcome to ADSplus, a centralised portal for all your advertising
          requirements.
        </p>
        <h2 className="mb-4 text-stratos font-bold uppercase">
          Sign in to ADSplus
        </h2>
        {state.error && (
          <ErrorMessage>Login failed. Invalid email or password.</ErrorMessage>
        )}
        <div className="mb-4">
          <FormLabel htmlFor="identifier" text="Email" />
          <FormInput
            name="identifier"
            type="text"
            onChangeHandler={(e) => handleChange(e)}
          />
        </div>
        <div className="mb-4">
          <FormLabel htmlFor="password" text="Password" />
          <FormInput
            name="password"
            type="password"
            onChangeHandler={(e) => handleChange(e)}
          />
        </div>
        <Button
          type="button"
          onClickHandler={(e) => handleSubmit(e)}
          color="stratos"
          loading={state.loading}
        >
          Sign In
        </Button>
        <Link href="/auth/reset-password">
          <a className="mt-2 text-xs hover:underline">Forgot Password?</a>
        </Link>
      </div>
      <p className="mb-4">Not registered? Want to learn more?</p>
      <Button href="mailto:hjennings@marketforce.com.au" color="stratos">
        Contact Us
      </Button>
    </>
  );
};

export default SignInForm;
