import React from 'react';

interface Props {
  children: string;
  nomargin?: boolean;
}

const PageHeading = ({ children, nomargin }: Props) => {
  return (
    <h1
      className={`${
        nomargin ? '' : 'mb-8'
      } text-3xl font-extrabold uppercase text-mineshaft`}
    >
      {children}
    </h1>
  );
};

export default PageHeading;
