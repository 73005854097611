import { useSession } from 'next-auth/client';
import useSWR from 'swr';
import { fetchWithToken } from '../utils/fetcher';
import Layout from '../components/Layout';
import SignInForm from '../components/SignInForm';
import SignInLayout from '../components/SignInLayout';
import Loading from '../components/common/Loading';
import ErrorMessage from '../components/ErrorMessage';
import LoggedInLayout from '../components/LoggedInLayout';
import AlertFeed from '../components/AlertFeed';
import PageHeading from '../components/common/PageHeading';
import Button from '../components/common/Button';
import ReferenceMaterials from '../components/ReferenceMaterials';

export default function CurrentOrders() {
  const [session, loading] = useSession();
  const { data: userData, error: userError } = useSWR(
    session
      ? [`${process.env.NEXT_PUBLIC_API_URL}/users/me`, session.jwt]
      : null,
    fetchWithToken,
  );

  console.log(userData);

  const { data: orderData, error: organisationError } = useSWR(
    () => [
      `${process.env.NEXT_PUBLIC_API_URL}/organisations/${encodeURIComponent(
        userData?.organisation?.id,
      )}/current-orders`,
      session.jwt,
    ],
    fetchWithToken,
  );

  const { data: alertData, error: alertError } = useSWR(
    () => [`${process.env.NEXT_PUBLIC_API_URL}/alerts/`, session.jwt],
    fetchWithToken,
  );

  const { data: materialsData, error: materialsError } = useSWR(
    () => [
      `${process.env.NEXT_PUBLIC_API_URL}/reference-materials?populate[0]=file`,
      session.jwt,
    ],
    fetchWithToken,
  );

  if (typeof window !== 'undefined' && loading) return null;

  if (userError || organisationError || alertError || materialsError) {
    return (
      <Layout>
        <ErrorMessage>Error fetching data!</ErrorMessage>
      </Layout>
    );
  }

  if (session && !orderData) return <Loading />;

  if (session && orderData) {
    return (
      <Layout>
        <main className="container  mx-auto px-4 py-8 grid md:grid-cols-4 gap-8">
          <div className="md:mx-16 py-8 md:py-16 md:col-span-3">
            <LoggedInLayout
              user={userData}
              organisation={userData?.organisation}
            />
            <PageHeading>Orders</PageHeading>
            {orderData.length > 0 && (
              <>
                <p>
                  You have currently
                  <span className="font-semibold"> {orderData.length} </span>
                  orders.
                </p>
                <div className="flex gap-x-5 mt-5">
                  <Button
                    type="button"
                    href="/orders/current"
                    color="stratos"
                    size="sm"
                  >
                    See your orders
                  </Button>
                  <Button
                    type="button"
                    href="/orders/create"
                    color="torchred"
                    size="sm"
                  >
                    Create a new order
                  </Button>
                </div>
              </>
            )}
            {(!orderData || orderData.length === 0) && (
              <>
                <p>You have no current orders.</p>
                <div className="flex mt-5">
                  <Button
                    type="button"
                    href="/orders/create"
                    color="torchred"
                    size="sm"
                  >
                    Create a new order
                  </Button>
                </div>
              </>
            )}
            {!!materialsData && materialsData.length > 0 && (
              <ReferenceMaterials files={materialsData} />
            )}
          </div>
          {!!alertData && <AlertFeed alerts={alertData?.data ?? []} />}
        </main>
      </Layout>
    );
  }
  return (
    <SignInLayout>
      <SignInForm />
    </SignInLayout>
  );
}
