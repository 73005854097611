import Spinner from './Spinner';

const Loading = () => {
  return (
    <div className="w-screen h-screen flex flex-col gap-2 items-center justify-center">
      <Spinner />
      <p className="text-sm">ADSplus Loading</p>
    </div>
  );
};

export default Loading;
