import React from 'react';
import { format } from 'date-fns';

interface Alert {
  id: number;
  title: string;
  date: Date;
  message: string;
  publishedAt: Date;
}
interface Alerts {
  alerts: Alert[];
}

const AlertFeed = ({ alerts }: Alerts) => {
  return (
    <div className="mt-0 mb-8 md:my-12 p-6 bg-slate-50	w-full rounded-lg">
      <h2 className="font-bold  text-torchred uppercase">Alerts</h2>
      <div className="grid grid-cols-1 divide-y">
        {!!alerts?.length &&
          alerts?.map((alert) => (
            <div key={alert?.id} className="py-6">
              <p className="text-sm text-slate-400">
                {alert?.date
                  ? format(new Date(alert?.date), 'dd/MM/yyyy, HH:mm')
                  : format(new Date(alert?.publishedAt), 'dd/MM/yyyy, HH:mm')}
              </p>

              <h3 className="text-sm font-semibold uppercase text-mineshaft">
                {alert?.title}
              </h3>
              {alert?.message && (
                <p className="text-xs text-slate-600">{alert.message}</p>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default AlertFeed;
