import React from 'react';
import { User, Organisation } from '../types/AccountData';
import PageHeading from './common/PageHeading';
import Image from 'next/image';

interface AccountData extends Organisation, User {}

const LoggedInLayout = ({ organisation, user }: AccountData) => {
  // Calc size of logo image to accomodate various resolutions if image exists
  let logoWidth: number;
  let logoAspect: number;
  let logoHeight: number;
  if (organisation?.logo) {
    logoWidth = Math.min(organisation.logo.width, 300);
    logoAspect = organisation.logo.width / organisation.logo.height;
    logoHeight = logoWidth / logoAspect;
  }
  return (
    <div className="pb-16">
      {organisation?.logo && (
        <div className="mb-4">
          <Image
            src={organisation.logo.url}
            alt={organisation.name}
            width={logoWidth}
            height={logoHeight}
            objectFit={'cover'}
          />
        </div>
      )}
      <PageHeading>{`Welcome ${user?.username}`}</PageHeading>
      <table>
        <thead>
          <tr>
            <th className="text-left">Your organisation:</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{organisation?.name} </td>
          </tr>
          {!!organisation?.contactAddress?.address1 && (
            <tr>
              <td>{organisation?.contactAddress.address1}</td>
            </tr>
          )}
          {!!organisation?.contactAddress?.address2 && (
            <tr>
              <td>{organisation?.contactAddress.address2}</td>
            </tr>
          )}
          {!!organisation?.contactAddress?.address3 && (
            <tr>
              <td>{organisation?.contactAddress.address3}</td>
            </tr>
          )}
          {(!!organisation?.contactAddress?.city ||
            !!organisation?.contactAddress?.postcode ||
            !!organisation?.contactAddress?.state) && (
            <tr>
              <td>
                {organisation?.contactAddress?.city},{' '}
                {organisation?.contactAddress?.state}{' '}
                {organisation?.contactAddress?.postcode}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default LoggedInLayout;
